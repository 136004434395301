<template>
  <div class="w-96 px-6 py-6">
    <p class="font-grey-darker-100 text-base font-bold base-border-grey-100 border-b pb-4"> 
      <template v-if="seachHistory">
        Search History
      </template>
      <template v-else>
        Example Search Queries 
      </template>
    </p>
    <div class="mt-4">
      <p 
        v-for="(search, index) in searchQueries"
        :key="`${search.name}${index}`"
        class="text-sm font-bold text-black mb-2 cursor-pointer"
        @click="setSearchQuery(search)"
      >
        {{ search.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExampleSearchQueries',
  data () {
    return{
      searchQueries: [ { name: `"Do your own research"`, bucket: 'bid_qQZwYpvDp' }, { name: `"anti white"~5`, bucket: 'bid_nROMRpJxl' } ],
      seachHistory: false
    }
  },
  methods: {
    setSearchQuery(arg) {
      this.$router.push(`/search/q/${arg.name}/bucket/${arg.bucket}`)
    }
  },
  mounted(){
    let localStorageQuesries = JSON.parse(this.$localStorage.get('searchQueries'))
    this.seachHistory = Boolean(localStorageQuesries)
    if(localStorageQuesries && localStorageQuesries.length){
      this.searchQueries = localStorageQuesries
      this.searchQueries = this.searchQueries.sort((a, b) => this.$moment(b.date).unix() - this.$moment(a.date).unix())
    }
  }
}
</script>